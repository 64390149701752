import React from "react";
import * as auth from "../context/auth/auth-provider";

const FullPageSpinner: React.FC = () => {
  const [logo, setLogo] = React.useState("");

  React.useEffect(() => {
    auth
      .getOrganization()
      .then((result) => {
        if (result) {
          let { org_theme } = result;
          console.log(org_theme);
          setLogo(org_theme);
        }
      })
      .catch((e) => {
        let default_logo = "default";
        setLogo(default_logo);
      });
  }, []);
  return (
    <div>
      <div
        data-role="page"
        id="Loading_aspx"
        tabIndex={0}
        className="ui-page ui-page-theme-a ui-page-active"
        style={{}}
        data-url="Loading_aspx"
      >
        <div data-role="content" className="ui-content" role="main">
          <div id="twMainContentView">
            <div id="maLogoWrap">
              {logo && (
                <img
                  id="loading-image"
                  src={`${process.env.PUBLIC_URL}/${logo}.png`}
                  alt="Loading..."
                />
              )}
            </div>
          </div>
        </div>
        <div id="twInfoContentView" className="fieldHide" />
      </div>
      <div className="ui-loader ui-corner-all ui-body-a ui-loader-default">
        <span className="ui-icon-loading" />
        <h1>loading</h1>
      </div>
    </div>
  );
};

export default FullPageSpinner;
